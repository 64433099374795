import { createContext } from "react";
import { Plano } from "../../types/Plano";
import Configuracoes from "../../types/Configuracoes";
import configuracoes from "../../types/Configuracoes";
import { FreteEspecial } from "../../types/FreteEspecial";
import { Cupom } from "../../types/Cupom";
import { FreteGratis } from "../../types/FreteGratis";

export type ConfiguracoesContextType = {
  configuracoes: Configuracoes;
  setConfiguracoes: (configuracoes: Configuracoes) => void;
  fretes_especiais: Array<FreteEspecial>;
  setFretes_especiais: (fretes_especiais: Array<FreteEspecial>) => void;
  fretes_gratis: Array<FreteGratis>;
  cep_gratis: Array<FreteGratis>;
  setFretes_gratis: (fretes_gratis: Array<FreteGratis>) => void;
  politica_de_privacidade: string;
  politica_de_troca_e_devolucao: string;
  processarLogo: (base64: string) => void;
  setPolitica_de_privacidade: (politica_de_privacidade: string) => void;
  setPolitica_de_troca_e_devolucao: (politica_de_troca_e_devolucao: string) => void;
  salvarConfiguracoes: () => Promise<boolean>;
  requestConfiguracoes: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  cupons: Array<Cupom>;
  setCupons: (cupons: Array<Cupom>) => void;
};

export const ConfiguracoesContext = createContext<ConfiguracoesContextType>(
  null!
);
