import styled from 'styled-components';
import { ThemeType } from '../types/Theme';

export const BotoesContainer = styled.div<{theme: ThemeType}>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2rem 2rem 0 2rem;
    .cancelar {
      cursor: pointer;
      font-size: 2.5rem;
      color: ${({theme}) => theme.colors.brandSecondary};
      padding: 1rem;
    }
`;

export const Titulo = styled.div<{theme: ThemeType, textAlign?: 'center' | 'left' | 'right'}>`
  width: 100%;
  font-weight: bold;
  text-align: ${({textAlign}) => textAlign || 'center'};
  font-size: 3rem;
  color: ${({theme}) => theme.colors.titleColor};
`;

export const Subtitulo = styled.div<{theme: ThemeType}>`
  width: 100%;
  text-align: center;
  font-size: 2.3rem;
  color: ${({theme}) => theme.colors.brandSecondary};
`;
