import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as VoltarIcon } from "../../../assets/newImages/icons/arrowLeft.svg";
import { ReactComponent as Plus } from "../../../assets/newImages/icons/plus.svg";
import { Button } from "../../../components/Button/index.tsx";
import { FooterPaginas } from "../../../components/FooterPaginas/FooterPaginas.tsx";
import Form, { FormRef } from "../../../components/Form/index.tsx";
import ImgWithLoading from "../../../components/ImgWithLoading/ImgWithLoading.tsx";
import { Input } from "../../../components/Input/index.tsx";
import Modal from "../../../components/Modal/Modal.tsx";
import ModalSucesso from "../../../components/Modal/ModalSucesso/index.tsx";
import PopoverInfo from "../../../components/PopoverInfo/PopoverInfo.tsx";
import { Select } from "../../../components/Select/index.tsx";
import SkeletonCadastroProduto from "../../../components/Skeletons/SkeletonCadastroProduto/SkeletonCadastroProduto.tsx";
import { SlidingBox } from "../../../components/SlidingBox/index.tsx";
import Tabs from "../../../components/Tabs/Tabs.tsx";
import { Textarea } from "../../../components/Textarea/index.tsx";
import { Toggle } from "../../../components/Toggle/Toggle.tsx";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import { configuracoesApi } from "../../../hooks/configuracoesApi.ts";
import { listasDePrecoApi } from "../../../hooks/listasDePreco.ts";
import { produtoApi } from "../../../hooks/produtoApi.ts";
import { produtosApi } from "../../../hooks/produtosApi.ts";
import { currencyMask } from "../../../services/Masks.tsx";
import { unmaskToInt } from "../../../services/Unmasks.tsx";
import { CategoriaBox } from "./cadastrosBox/Categorias/Categorias.tsx";
import { ListaDePrecoBox } from "./cadastrosBox/listasDePreco/ListasDePreco.tsx";
import { MidiasBox } from "./midiasBox/midiasBox.tsx";
import "./styles.ts";
import { FooterWrapper, ViewContainer } from "./styles.ts";
import { VariacoesBox } from "./variacoesBox/VariacoesBox.tsx";

function Produto() {
  const [id_produto, setID] = useState(useParams().id);
  const { theme } = useTheme();

  const [tab, setTab] = useState(0);
  const [produto, setProduto] = useState<any>({
    id: id_produto,
    id_pasta: 0,
    id_empresa: 0,
    nome: "",
    referencia: "",
    descricao: "",
    peso: null,
    data_publicacao: new Date(),
    observacao_interna: "",
    multiplicador: 1,
    quantidade_minima: 1,
    solicitar_observacao: "0",
    fotos: [],
    variacoes: [],
    ativo: 1,
  });

  const ConfiguracoesApi = configuracoesApi();

  const [config_integracao, setConfigIntegracao] = useState({
    permitir_produtos: false,
    permitir_imagens: false,
    permitir_observacoes: false,
  });

  useEffect(() => {
    const init = async () => {
      const res = await ConfiguracoesApi.verifica_config_integracoes();
      setConfigIntegracao({
        permitir_produtos: res.upload_produtos,
        permitir_imagens: res.upload_imagens,
        permitir_observacoes: res.upload_configurar_observacao,
      });
    };
    init();
  }, []);

  const ApiListasDePreco = listasDePrecoApi();
  const [listasDePreco, setListasDePreco] = useState<any>([]);
  const [temVariacao, setTemVariacao] = useState(false);
  const [temEstoque, setTemEstoque] = useState(false);
  const [categorias, setCategorias] = useState<any>([]);
  const [needInit, setNeedInit] = useState(true);
  const [novaCategoria, setNovaCategoria] = useState(0);
  const [novaListaDePreco, setNovaListaDePreco] = useState(0);
  const [loading, setLoading] = useState(true);

  const [mouseSobreImagem, setMouseSobreImagem] = useState(false);

  const formRef = useRef<FormRef | null>(null);
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();

  const [textoBotao, setTextoBotao] = useState("Salvar");

  const [midiasBox, setMidiasBox] = useState(false);

  const [modalAtualizando, setModalAtualizando] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [stateModal, setStateModal] = useState("loading");

  const [auxAvancado, setAuxAvancado] = useState({
    data_publicacao: moment().format("YYYY-MM-DD HH:mm"),
    solicitar_observacao: "0",
    multiplicador: "1",
    quantidade_minima: "1",
    observacao_interna: "",
  });

  const [salvando, setSalvando] = useState(false);

  const [modalCategoria, setModalCategoria] = useState(false);
  const [modalListaDePreco, setModalListaDePreco] = useState(false);

  const [variacaoUnica, setVariacaoUnica] = useState({
    id: "0",
    id_produto: produto.id,
    variacao: "",
    estoque:
      !loading && produto.variacoes.length ? produto.variacoes[0].estoque : "0",
    ativo: 1,
    precos: [
      ...listasDePreco
        .filter((tabela) => Number(tabela.ativo))
        .map((tabela) => ({
          id: "0",
          id_variacao: "0",
          id_tabela: tabela.id,
          preco: "0",
        })),
    ],
  });

  const [activeTab, setActiveTab] = useState("informacoes");

  const [tabs, setTabs] = useState([
    { name: "Informações", value: "informacoes" },
    { name: "Mídias", value: "midias" },
    // {name: "Avançado", value: "avancado"},
  ]);

  function generateRandomString() {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";

    for (let i = 0; i < 3; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    for (let i = 0; i < 4; i++) {
      result += digits.charAt(Math.floor(Math.random() * digits.length));
    }

    return result;
  }

  function novaVariacao() {
    const nova_variacao = {
      id: "0",
      id_produto: produto.id,
      variacao: "",
      ativo: "1",
      estoque: "0",
      precos: [
        ...listasDePreco.map((tabela) => {
          return {
            id: "0",
            id_variacao: "0",
            id_tabela: tabela.id,
            preco: "0",
          };
        }),
      ],
    };

    setProduto({
      ...produto,
      variacoes: [...produto.variacoes, nova_variacao],
    });
  }

  const handleExcluirProduto = () => {
    Swal.fire({
      title: "Tem certeza que deseja inativar este produto?",
      icon: "warning",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Inativar",
      showCancelButton: true,
      showCloseButton: true,
      customClass: {
        confirmButton: 'btn-confirm',
        cancelButton: 'btn-cancel',
        icon: 'custom-swal-icon',
        title: 'custom-swal-title'
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Tem certeza?",
          html: "Ao inativar, seu produto não ficará mais <br />disponível nos catálogos, mas poderá reativar posteriormente, em <b>Meus Produtos<b/>.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Inativar (5)",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: 'btn-confirm2',
            popup: 'custom-swal-popup',
            icon: 'custom-swal-icon',
            title: 'custom-swal-title',
          },
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            if (confirmButton) {
                confirmButton.disabled = true;
                let secondsLeft = 5;
                const timerInterval = setInterval(() => {
                    secondsLeft--;
                    confirmButton.innerText = `Inativar (${secondsLeft})`;
                    if (secondsLeft <= 0) {
                      confirmButton.disabled = false;
                      confirmButton.innerText = "Inativar";
                    }
                }, 1000);
            }
        },
        }).then((result) => {
          if (result.isConfirmed) {
            inativaProduto()
              .then(() => {
                Swal.fire({
                  title: "Sucesso!",
                  text: "Produto inativado com sucesso",
                  icon: "success",
                  timer: 1250,
                  showConfirmButton: false,
                });

                navigate("/painel/produtos");
                setSalvando(false);
              })
              .catch(() => {
                Swal.fire({
                  title: "Erro!",
                  text: "Erro ao inativar o produto",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
                setSalvando(false);
              });
          }
        });
      }
    });
  };

  function adicionaFoto(newImage: {
    base64: string;
    imagem: string;
    size: number;
  }) {
    const fotos = [
      ...produto.fotos,
      {
        base64: newImage.base64,
        id: "0",
        id_produto: produto.id,
        imagem: newImage.imagem,
        ordem: produto.fotos.length,
        tamanho: newImage.size,
        tipo: 1,
      },
    ];
    setProduto({
      ...produto,
      fotos: [...fotos],
    });
  }

  function adicionaVideo(url_video) {
    let video = {
      id: "0",
      id_produto: produto.id,
      imagem: url_video,
      ordem: produto.fotos.length,
      tipo: 2,
      miniaturaVideo: "",
    };
    // Pega Thumbnail do vídeo do YouTube
    video.miniaturaVideo = obterMiniaturaVideoYouTube(video.imagem);
    setProduto({
      ...produto,
      fotos: [...produto.fotos, video],
    });
  }

  function obterMiniaturaVideoYouTube(link) {
    // Pega Thumbnail do vídeo do YouTube
    let miniaturaVideo = link.split("/")[3];
    miniaturaVideo = miniaturaVideo.split("=");
    // Verifica se link veio do mobile (que é diferente)
    miniaturaVideo =
      miniaturaVideo.length == 2 ? miniaturaVideo[1] : miniaturaVideo[0];
    miniaturaVideo = "https://img.youtube.com/vi/" + miniaturaVideo + "/0.jpg";
    return miniaturaVideo;
  }

  function replicaEstoques(estoque) {
    const variacoes = [...produto.variacoes];
    variacoes.forEach((variacao) => {
      variacao.estoque = estoque;
    });
    setProduto({
      ...produto,
      variacoes: [...variacoes],
    });
  }

  function replicaNomesVariacoes(nome) {
    const variacoes = [...produto.variacoes];
    variacoes.forEach((variacao) => {
      variacao.variacao = nome;
    });
    setProduto({
      ...produto,
      variacoes: [...variacoes],
    });
  }

  function replicaPrecoTabela(preco, index) {
    const variacoes = [...produto.variacoes];
    variacoes.forEach((variacao) => {
      variacao.precos[index].preco = preco;
    });
    setProduto({
      ...produto,
      variacoes: [...variacoes],
    });
  }

  async function salvarProduto() {
    if (produto.id_pasta == 0) {
      Swal.fire({
        title: "Erro!",
        text: "Selecione uma categoria",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (produto.fotos.length == 0) {
      Swal.fire({
        title: "Erro!",
        text: "Adicione uma imagem ao produto",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setActiveTab("midias");
      return;
    }

    if (produto.ativo == 0) {
      let response = await Swal.fire({
        icon: "question",
        title: "Deseja reativar o produto?",
        showCancelButton: true,
        confirmButtonText: "Reativar",
        cancelButtonText: "Cancelar",
      });
      if (!response.isConfirmed) {
        return;
      }
    }

    setSalvando(true);

    // Clone the produto object
    const produtoClone = { ...produto };

    // Remove the description if it's null
    produtoClone.descricao = produtoClone.descricao || "";
    produtoClone.ativo = 1;

    // Format photos and adjust their properties
    produtoClone.fotos.forEach((foto, index) => {
      foto.ordem = index;
      if (foto.miniaturaVideo) {
        foto.imagem_externa = foto.miniaturaVideo;
      }
    });

    // Handle variations
    if (!temVariacao) {
      produtoClone.variacoes = produtoClone.variacoes.filter(
        (variacao) => Number(variacao.id) != 0
      );
      produtoClone.variacoes.forEach((variacao) => {
        variacao.ativo = 0;
      });
      if (!temEstoque) {
        delete variacaoUnica.estoque;
      }
      // Copia o id da variacao existente
      variacaoUnica.id = produto.id != "0" ? produtoClone.variacoes[0].id : "0";

      // Atribui os outros campos
      produtoClone.variacoes[0] = variacaoUnica;
    } else {
      produtoClone.variacoes.forEach((variacao) => {
        if (variacao.estoque === null || !temEstoque) {
          delete variacao.estoque;
        }
      });
    }

    // Format the publication date
    produtoClone.data_publicacao = moment(produtoClone.data_publicacao).format(
      "YYYY-MM-DD HH:mm"
    );

    // Remove unnecessary property
    delete produtoClone.variacoes_precos;

    delete produtoClone.catalogos;

    return saveProductToApi(produtoClone)
      .then(() => {
        Swal.fire({
          title: "Sucesso!",
          text: "Produto salvo com sucesso",
          icon: "success",
          timer: 1250,
          showConfirmButton: false,
        });

        navigate("/painel/produtos");
        setSalvando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error === "REFERENCIA_JA_EXISTE") {
          Swal.fire({
            title: "Erro!",
            text: "Já existe um produto com esse SKU, por favor, escolha outro SKU.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "Erro!",
            text: "Erro ao salvar produto",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        setSalvando(false);
      });
  }

  function saveProductToApi(produtoClone) {
    // Return the promise from the API call
    return produtoApi().produtoCriar(produtoClone);
  }

  function inativaProduto() {
    // Return the promise from the API call
    return produtoApi().produtoInativar(produto.id);
  }

  function deletaVariacao(index: number) {
    produto.variacoes[index].ativo = "0";

    //se todas as variacoes estiverem inativas seta temVariacao para false

    let temVariacao = false;

    produto.variacoes.forEach((variacao) => {
      if (Number(variacao.ativo) == 1) {
        temVariacao = true;
      }
    });

    setTemVariacao(temVariacao);

    setProduto({
      ...produto,
      variacoes: [...produto.variacoes],
    });
  }

  function Avancar() {
    if (activeTab == "avancado") {
      setActiveTab("informacoes");
      setProduto({
        ...produto,
        quantidade_minima: auxAvancado.quantidade_minima,
        data_publicacao: auxAvancado.data_publicacao,
        multiplicador: auxAvancado.multiplicador,
        solicitar_observacao: auxAvancado.solicitar_observacao,
        observacao_interna: auxAvancado.observacao_interna,
      });

      tabs.pop();
      setTabs([...tabs]);
    } else if (activeTab == "midias") {
      salvarProduto();
    } else if (activeTab == "informacoes") {
      formRef.current && formRef.current.submitForm();
      if (!isValid) return;
      setActiveTab("midias");
    }
  }

  function Voltar() {
    if (activeTab == "avancado") {
      setActiveTab("informacoes");
      setAuxAvancado({
        quantidade_minima: produto.quantidade_minima,
        data_publicacao: produto.data_publicacao,
        multiplicador: produto.multiplicador,
        solicitar_observacao: produto.solicitar_observacao,
        observacao_interna: auxAvancado.observacao_interna,
      });

      //remove o ultimo indice de tabs
      tabs.pop();
      setTabs([...tabs]);
    } else if (activeTab == "midias") {
      setActiveTab("informacoes");
    } else if (activeTab == "informacoes") {
      //todo
    }
  }

  function removeImagem(index) {
    const fotos = [...produto.fotos];
    fotos.splice(index, 1);
    setProduto({
      ...produto,
      fotos: [...fotos],
    });
  }

  function moveImagemEsquerda(index) {
    const fotos = [...produto.fotos];
    const foto = fotos[index];
    fotos[index] = fotos[index - 1];
    fotos[index - 1] = foto;
    setProduto({
      ...produto,
      fotos: [...fotos],
    });
  }

  function moveImagemDireita(index) {
    const fotos = [...produto.fotos];
    const foto = fotos[index];
    fotos[index] = fotos[index + 1];
    fotos[index + 1] = foto;
    setProduto({
      ...produto,
      fotos: [...fotos],
    });
  }

  function Avancado() {
    const newTabs = [...tabs];
    if (newTabs.length === 2) {
      newTabs.push({ name: "Avançado", value: "avancado" });
      setActiveTab("avancado");
    }
    setTabs(newTabs);
  }

  useEffect(() => {
    if (activeTab == "avancado") {
      setTextoBotao("Salvar");
    } else if (activeTab == "midias") {
      setTextoBotao("Salvar e criar o produto");
    } else if (activeTab == "informacoes") {
      setTextoBotao(`Salvar`);
    }
  }, [activeTab]);

  useEffect(() => {
    const init = async () => {
      const id = Number(id_produto);
      let response = produto;
      const ListasResult = await ApiListasDePreco.getListas(true);
      const result_categorias = await produtosApi().getCategorias();
      if (id_produto != "0") {
        response = await produtoApi().getProduto(id);
      } else {
        response.variacoes = [];
      }

      setListasDePreco(ListasResult);

      if (
        (response.variacoes.length > 1 && loading) ||
        (response.variacoes.length == 1 && response.variacoes[0].variacao != "")
      ) {
        setTemVariacao(true);
      }

      setCategorias([
        ...result_categorias.map((categoria) => {
          return {
            name: categoria.descricao,
            value: categoria.id,
          };
        }),
      ]);

      let variacoes_id_0 = produto.variacoes.filter(
        (variacao) => Number(variacao.id) == 0
      );

      response.variacoes.push(...variacoes_id_0);

      if (response.variacoes.length == 0) {
        response.variacoes.push(variacaoUnica);
      }

      if (!response.variacoes_precos) {
        response.variacoes_precos = [];
      }

      response.variacoes.forEach((variacao) => {
        variacao.precos = [];

        if (variacao.estoque != null) setTemEstoque(true);

        ListasResult.forEach((tabela) => {
          const tabela_preco = {
            id: "0",
            id_variacao: variacao.id,
            id_tabela: tabela.id,
            preco: "0",
          };

          const preco_encontrado =
            response.variacoes_precos
              .map((preco) => {
                if (
                  Number(preco.id_variacao) === Number(variacao.id) &&
                  Number(preco.id_tabela) === Number(tabela_preco.id_tabela)
                ) {
                  return preco;
                }
              })
              .filter(Boolean)[0] || {};

          if (Object.keys(preco_encontrado).length) {
            tabela_preco.id = preco_encontrado.id;
            tabela_preco.preco = preco_encontrado.preco;
          } else {
            tabela_preco.preco = "0";
          }

          variacao.precos.push(tabela_preco);
        });
      });
      if (
        ListasResult.length > 0 &&
        response.variacoes.length > 0 &&
        loading &&
        response.id != "0"
      ) {
        const variacaoUnica = {
          id: "0",
          id_produto: produto.id,
          variacao: "",
          ativo: 1,
          estoque: response.id != "0" ? response.variacoes[0].estoque : "0",
          precos: [
            ...ListasResult.filter((tabela) => Number(tabela.ativo)).map(
              (tabela) => ({
                id: "0",
                id_variacao: "0",
                id_tabela: tabela.id,
                preco:
                  response.id != "0"
                    ? response.variacoes[0].precos
                        .filter(
                          (preco) =>
                            Number(preco.id_tabela) === Number(tabela.id)
                        )
                        .reduce((a, preco) => preco.preco, "0")
                    : "0",
              })
            ),
          ],
        };

        setVariacaoUnica(variacaoUnica);
      }

      if (!loading) {
        //adiciona apenas as novas response.variacoes.precos que nao existem nos produto.variacoes

        response.variacoes.forEach((variacao, index) => {
          if (
            produto &&
            produto.variacoes &&
            produto.variacoes[index] &&
            produto.variacoes[index].precos &&
            variacao &&
            variacao.precos &&
            produto.variacoes[index].precos.length !== variacao.precos.length
          ) {
            variacao.precos.forEach((preco, index_preco) => {
              if (produto.variacoes[index].precos[index_preco] === undefined) {
                produto.variacoes[index].precos.push(preco);
              }
            });
          }

          if (variacaoUnica.precos.length != variacao.precos.length) {
            variacao.precos.forEach((preco, index_preco) => {
              if (variacaoUnica.precos[index_preco] == undefined) {
                variacaoUnica.precos.push(preco);
              }
            });
          }
        });

        setProduto(produto);
      } else {
        setProduto(response);
      }

      setAuxAvancado({
        data_publicacao: response.data_publicacao,
        solicitar_observacao: response.solicitar_observacao,
        multiplicador: response.multiplicador,
        quantidade_minima: response.quantidade_minima,
        observacao_interna: response.observacao_interna,
      });

      setModalCategoria(false);

      setLoading(false);
    };

    init();
  }, [needInit]);

  useEffect(() => {
    const init = async () => {
      const result_categorias = await produtosApi().getCategorias();
      setCategorias([
        ...result_categorias.map((categoria) => {
          return {
            name: categoria.descricao,
            value: categoria.id,
          };
        }),
      ]);
    };
    init();
  }, [modalCategoria]);

  async function processaVariacoes(variacoes) {
    const ListasResult = await ApiListasDePreco.getListas();
    setListasDePreco(ListasResult);

    variacaoUnica.precos = [];

    ListasResult.forEach((tabela) => {
      const tabela_preco = {
        id: "0",
        id_variacao: variacaoUnica.id,
        id_tabela: tabela.id,
        preco:
          produto.variacoes.length > 0
            ? produto.variacoes[0].precos
                .filter(
                  (preco) => Number(preco.id_tabela) === Number(tabela.id)
                )
                .reduce((a, preco) => preco.preco, "0")
            : "0",
      };

      variacaoUnica.precos.push(tabela_preco);
    });

    variacoes.forEach((variacao) => {
      variacao.precos = [];

      if (variacao.estoque !== null) setTemEstoque(true);

      ListasResult.forEach((tabela) => {
        const tabela_preco = {
          id: "0",
          id_variacao: variacao.id,
          id_tabela: tabela.id,
          preco: "0",
        };

        const preco_encontrado = produto.variacoes_precos
          .filter(
            (preco) =>
              Number(preco.id_variacao) === Number(variacao.id) &&
              Number(preco.id_tabela) === Number(tabela_preco.id_tabela)
          )
          .reduce((a, preco) => preco, {});

        if (Object.keys(preco_encontrado).length) {
          tabela_preco.id = preco_encontrado.id;
          tabela_preco.preco = preco_encontrado.preco;
        } else {
          tabela_preco.preco = "0";
        }

        variacao.precos.push(tabela_preco);
      });
    });

    setProduto({
      ...produto,
      variacoes: [...variacoes],
    });
  }

  useEffect(() => {
    if (novaCategoria) {
      setProduto({
        ...produto,
        id_pasta: novaCategoria.toString(),
      });
    }
  }, [novaCategoria]);

  useEffect(() => {
    variacaoUnica.precos = [];
    listasDePreco.forEach((tabela) => {
      const tabela_preco = {
        id: "0",
        id_variacao: variacaoUnica.id,
        id_tabela: tabela.id,
        preco:
          produto.variacoes.length > 0
            ? produto.variacoes[0].precos
                .filter(
                  (preco) => Number(preco.id_tabela) === Number(tabela.id)
                )
                .reduce((a, preco) => preco.preco, "0")
            : "0",
      };
      variacaoUnica.precos.push(tabela_preco);
    });
  }, [listasDePreco]);

  return (
    <>
      <ViewContainer theme={theme}>
        {loading ? (
          <SkeletonCadastroProduto />
        ) : (
          <>
            <div className="left">
              <Tabs
                tabs={tabs}
                value={activeTab}
                onChange={(newValue) => {
                  setActiveTab(newValue);
                  if (newValue !== "avancado") {
                    setTabs(tabs.filter((tab) => tab.value !== "avancado"));
                  }
                }}
              />

              {activeTab == "informacoes" && (
                <div className="cadastro">
                  <label className="tem-variacao">
                    <div className="topo">
                      <Toggle
                        value={temVariacao}
                        onChange={(newValue) => {
                          //se todas as variacoes estiverem inativas cria uma variacao

                          let temVariacao = false;

                          produto.variacoes.forEach((variacao) => {
                            if (Number(variacao.ativo) === 1) {
                              temVariacao = true;
                            }
                          });

                          if (!temVariacao) {
                            novaVariacao();
                          }

                          setTemVariacao(newValue);
                        }}
                        disabled={!config_integracao.permitir_produtos}
                      />
                      <span>Esse produto tem variação?</span>
                    </div>
                    <div className="texto-variacao">
                      Ative essa opção acima se o seu produto possui variação de
                      características. Por exemplo: tamanho, cor e etc.
                    </div>
                  </label>
                  <Form id={""} setFormValid={setIsValid} ref={formRef}>
                    <div className="input">
                      <Input
                        label="Nome do produto"
                        sizeLabel="medium"
                        type="text"
                        name="nome"
                        value={produto.nome}
                        onChange={(newValue) => {
                          setProduto({
                            ...produto,
                            nome: newValue,
                          });
                        }}
                        placeholder="Nome"
                        error=""
                        disabled={!config_integracao.permitir_produtos}
                        maxLength={64}
                        required={true}
                      />
                    </div>
                    <div className="textarea">
                      <div className="label">Descrição</div>
                      <ReactQuill
                        className="quill-editor"
                        formats={["bold", "italic", "underline", "list"]}
                        value={produto.descricao}
                        readOnly={!config_integracao.permitir_produtos}
                        onChange={(newValue) => {
                          setProduto({
                            ...produto,
                            descricao: newValue,
                          });
                        }}
                        style={
                          !config_integracao.permitir_produtos
                            ? { opacity: 0.5 }
                            : {}
                        }
                      ></ReactQuill>
                    </div>
                    <div className="sku-peso">
                      <div className="sku">
                        <Input
                          label="SKU"
                          sizeLabel="medium"
                          type="text"
                          name="sku"
                          showMaxLength={false}
                          value={produto.referencia}
                          onChange={(newValue) => {
                            setProduto({
                              ...produto,
                              referencia: newValue,
                            });
                          }}
                          placeholder="0000XXX"
                          error=""
                          maxLength={64}
                          disabled={!config_integracao.permitir_produtos}
                          required={true}
                        />
                        {config_integracao.permitir_produtos ? (
                          <div
                            className="gerar"
                            onClick={() => {
                              setProduto({
                                ...produto,
                                referencia: generateRandomString(),
                              });
                            }}
                          >
                            <div className="gerar-texto">Gerar</div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      <div className="peso">
                        <Input
                          label="Peso (g)"
                          sizeLabel="medium"
                          type="number"
                          name="peso"
                          value={produto.peso}
                          onChange={(newValue) => {
                            setProduto({
                              ...produto,
                              peso: newValue,
                            });
                          }}
                          placeholder="0"
                          step={"any"}
                          disabled={!config_integracao.permitir_produtos}
                          max={999999}
                          required={true}
                          showMaxLength={false}
                        />
                      </div>
                    </div>
                    <div className="categoria">
                      <Select
                        label="Categoria"
                        sizeLabel="medium"
                        name="categoria"
                        value={produto.id_pasta}
                        onChange={(newValue) => {
                          setProduto({
                            ...produto,
                            id_pasta: newValue,
                          });
                        }}
                        placeholder="Selecione uma categoria"
                        options={categorias}
                        disabled={!config_integracao.permitir_produtos}
                        required={true}
                      />
                      <div
                        className="mais"
                        onClick={() => {
                          if (!config_integracao.permitir_produtos) return;
                          setModalCategoria(true);
                        }}
                        style={
                          !config_integracao.permitir_produtos
                            ? { cursor: "not-allowed", opacity: "0.5" }
                            : {}
                        }
                      >
                        <Plus className="plus" />
                      </div>
                    </div>
                  </Form>
                  {!temVariacao &&
                    !loading &&
                    variacaoUnica.precos.length > 0 && (
                      <>
                        <div className="precos-lista">
                          {listasDePreco.map((tabela, index) => {
                            return (
                              <>
                                <div
                                  className={
                                    index == 0 ? "preco primeiro" : "preco"
                                  }
                                >
                                  <div className="preco-input">
                                    <Input
                                      sizeLabel="medium"
                                      type="text"
                                      name="nome"
                                      label="Preço"
                                      disabled={
                                        !config_integracao.permitir_produtos
                                      }
                                      mask={{
                                        mask: currencyMask,
                                        unmask: unmaskToInt,
                                        param: tabela.moeda,
                                      }}
                                      value={variacaoUnica.precos[index].preco}
                                      onChange={(newValue) => {
                                        let variacao = {
                                          ...variacaoUnica,
                                        };
                                        variacao.precos[index].preco = newValue;
                                        setVariacaoUnica(variacao);
                                      }}
                                      placeholder="Preco"
                                    />
                                    <div className="lista">
                                      <div className="titulo-lista">
                                        {tabela.descricao}
                                      </div>
                                    </div>
                                  </div>
                                  {(index == 0 &&
                                    !config_integracao.permitir_produtos && (
                                      <></>
                                    )) || (
                                    <div
                                      className="mais"
                                      onClick={() => {
                                        if (
                                          !config_integracao.permitir_produtos
                                        )
                                          return;
                                        setModalListaDePreco(true);
                                      }}
                                    >
                                      <Plus className="plus" />
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  <div className="tem-estoque">
                    <label>
                      <Toggle
                        value={temEstoque}
                        onChange={(newValue) => {
                          setTemEstoque(newValue);
                        }}
                        disabled={!config_integracao.permitir_produtos}
                      />
                      <span>Gerenciar estoque desse produto?</span>
                    </label>
                    <PopoverInfo
                      width="100rem"
                      mensagem="Este campo desativado é considerado estoque ilimitado."
                    />
                  </div>
                  {temEstoque &&
                    variacaoUnica.precos.length > 0 &&
                    !temVariacao && (
                      <div className="estoque-unico">
                        <Input
                          label="Estoque"
                          sizeLabel="medium"
                          type="text"
                          name="nome"
                          disabled={!config_integracao.permitir_produtos}
                          value={variacaoUnica.estoque}
                          onChange={(newValue) => {
                            setVariacaoUnica({
                              ...variacaoUnica,
                              estoque: newValue,
                            });
                          }}
                          placeholder="Estoque"
                        />
                      </div>
                    )}
                </div>
              )}

              {activeTab === "midias" && (
                <div className="midias">
                  {config_integracao.permitir_imagens && (
                    <div className="center">
                      <div
                        className="adicionar_produto"
                        onClick={() => {
                          setMidiasBox(!midiasBox);
                        }}
                      >
                        <div className="pai">
                          <Plus className="plus" />
                        </div>
                      </div>
                    </div>
                  )}
                  {produto.fotos.map((foto, index) => {
                    return (
                      <div
                        className="imagem"
                        onMouseEnter={() => {
                          if (config_integracao.permitir_imagens)
                            setMouseSobreImagem(true);
                        }}
                        onMouseLeave={() => {
                          if (config_integracao.permitir_imagens) {
                            setMouseSobreImagem(false);
                          }
                        }}
                      >
                        <div
                          className={`icone-apagar ${
                            mouseSobreImagem ? "visivel" : "invisivel"
                          }`}
                          onClick={() => {
                            removeImagem(index);
                          }}
                        >
                          <Plus />
                        </div>
                        {index != 0 && (
                          <div
                            className={`icone-esquerda ${
                              mouseSobreImagem ? "visivel" : "invisivel"
                            }`}
                            onClick={() => {
                              moveImagemEsquerda(index);
                            }}
                          >
                            <VoltarIcon />
                          </div>
                        )}
                        {index != produto.fotos.length - 1 && (
                          <div
                            className={`icone-direita ${
                              mouseSobreImagem ? "visivel" : "invisivel"
                            }`}
                            onClick={() => {
                              moveImagemDireita(index);
                            }}
                          >
                            <VoltarIcon />
                          </div>
                        )}
                        {(foto.tipo == 1 && (
                          <ImgWithLoading
                            src={
                              foto.imagem_externa_2
                                ? foto.imagem_externa_2
                                : foto.imagem_externa
                                ? foto.imagem_externa
                                : foto.base64
                            }
                            alt=""
                          />
                        )) || (
                          <ImgWithLoading
                            src={obterMiniaturaVideoYouTube(
                              foto.imagem_externa || foto.imagem
                            )}
                            alt=""
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              {activeTab === "avancado" && (
                <>
                  <div className="avancado">
                    <div className="titulo-avancado">
                      Para garantir a melhor experiência de compra em seu
                      catálogo, recomendamos que as configurações avançadas só
                      sejam alteradas se você entender a funcionalidade do
                      recurso.
                    </div>
                    <div className="inputs-avancado">
                      <div className="bloco-input">
                        <Input
                          label="Quantidades mínimas de compra"
                          sizeLabel="medium"
                          type="text"
                          maxLength={5}
                          disabled={!config_integracao.permitir_produtos}
                          labelIcon={
                            <PopoverInfo
                              width="78rem"
                              mensagem="O cliente não poderá comprar menos que essa quantidade por produto."
                            />
                          }
                          name="quantidade_minima"
                          allowEmojis={false}
                          value={auxAvancado.quantidade_minima.toString()}
                          onChange={(newValue) => {
                            setAuxAvancado({
                              ...auxAvancado,
                              quantidade_minima: newValue,
                            });
                          }}
                          placeholder="1"
                        />
                        <Input
                          label="Multiplicador"
                          maxLength={5}
                          sizeLabel="medium"
                          type="text"
                          disabled={!config_integracao.permitir_produtos}
                          labelIcon={
                            <PopoverInfo
                              width="86rem"
                              mensagem="Produtos com multiplicador 2, por exemplo, só podem ser comprados de 2 em 2."
                            />
                          }
                          name="multiplicador"
                          value={auxAvancado.multiplicador.toString()}
                          allowEmojis={false}
                          onChange={(newValue) => {
                            setAuxAvancado({
                              ...auxAvancado,
                              multiplicador: newValue,
                            });
                          }}
                          placeholder="1"
                        />
                      </div>
                      <div className="bloco-input">
                        <Select
                          label="Cliente pode inserir observações?"
                          sizeLabel="medium"
                          name="observação"
                          disabled={
                            !config_integracao.permitir_produtos &&
                            !config_integracao.permitir_observacoes
                          }
                          value={auxAvancado.solicitar_observacao}
                          onChange={(newValue) => {
                            setAuxAvancado({
                              ...auxAvancado,
                              solicitar_observacao: newValue,
                            });
                          }}
                          options={[
                            {
                              name: "Sim",
                              value: "1",
                            },
                            {
                              name: "Não",
                              value: "0",
                            },
                          ]}
                        />

                        <Input
                          label="Data de publicação"
                          sizeLabel="medium"
                          allowEmojis={false}
                          type="datetime-local"
                          disabled={!config_integracao.permitir_produtos}
                          onChange={(newValue) => {
                            setAuxAvancado({
                              ...auxAvancado,
                              data_publicacao: newValue,
                            });
                          }}
                          value={moment(
                            auxAvancado.data_publicacao,
                            "YYYY-MM-DD HH:mm"
                          ).format("YYYY-MM-DDTHH:mm")}
                          placeholder="2023-04-27T11:28"
                        />
                      </div>
                      <div className="bloco-input">
                        <Textarea
                          label="Observação interna"
                          sizeLabel="medium"
                          type="text"
                          maxLength={255}
                          disabled={
                            !config_integracao.permitir_produtos &&
                            !config_integracao.permitir_observacoes
                          }
                          labelIcon={
                            <PopoverInfo
                              width="100%"
                              mensagem="Está observação ficará visivel somente no cadastro e edição do produto, não aparecerá para o seu Cliente."
                            />
                          }
                          onChange={(newValue) => {
                            setAuxAvancado({
                              ...auxAvancado,
                              observacao_interna: newValue,
                            });
                          }}
                          value={auxAvancado.observacao_interna}
                          placeholder="Insira a observação interna aqui"
                        />
                      </div>
                    </div>

                    {produto.catalogos && produto.catalogos.length > 0 && (
                      <div className="catalogos">
                        <div className="linha" />
                        <div className="titulo-catalogos">
                          Catálogos em que o produto está adicionado:
                        </div>
                        {produto.catalogos.map((catalogo) => {
                          return <div className="nome">- {catalogo}</div>;
                        })}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="middle hideMobile">
              <div className="linha" />
            </div>
            <div className="right">
              <SlidingBox
                active={temVariacao && activeTab == "informacoes"}
                width="100%"
                direction="right"
              >
                <VariacoesBox
                  setProdutoVariacoes={setTemVariacao}
                  onChangePreco={(newValue: {
                    index_variacao: number;
                    index_preco: number;
                    preco: string;
                  }) => {
                    setProduto({
                      ...produto,
                      variacoes: produto.variacoes.map(
                        (variacao, index_variacao) => {
                          if (index_variacao == newValue.index_variacao) {
                            variacao.precos[newValue.index_preco].preco =
                              newValue.preco;
                          }
                          return variacao;
                        }
                      ),
                    });
                  }}
                  replicaEstoques={(estoque) => {
                    replicaEstoques(estoque);
                  }}
                  replicaNomeVariacoes={(nome) => {
                    replicaNomesVariacoes(nome);
                  }}
                  replicaPrecoTabela={(preco, index) => {
                    replicaPrecoTabela(preco, index);
                  }}
                  onChangeEstoque={(newValue: {
                    index_variacao: number;
                    estoque: string;
                  }) => {
                    setProduto({
                      ...produto,
                      variacoes: produto.variacoes.map(
                        (variacao, index_variacao) => {
                          if (index_variacao == newValue.index_variacao) {
                            variacao.estoque = newValue.estoque;
                          }
                          return variacao;
                        }
                      ),
                    });
                  }}
                  onChangeNomeVariacao={(newValue: {
                    index_variacao: number;
                    nome: string;
                  }) => {
                    setProduto({
                      ...produto,
                      variacoes: produto.variacoes.map(
                        (variacao, index_variacao) => {
                          if (index_variacao == newValue.index_variacao) {
                            variacao.variacao = newValue.nome;
                          }
                          return variacao;
                        }
                      ),
                    });
                  }}
                  novaVariacao={() => {
                    novaVariacao();
                  }}
                  permitir_edicao={config_integracao.permitir_produtos}
                  deletaVariacao={(index) => {
                    deletaVariacao(index);
                  }}
                  novaListaDePreco={() => {
                    setModalListaDePreco(true);
                  }}
                  temEstoque={temEstoque}
                  variacoes={produto.variacoes}
                  variacoesPrecos={produto.variacoes_precos}
                  listasDePreco={listasDePreco}
                />
              </SlidingBox>
              <SlidingBox
                active={midiasBox && activeTab == "midias"}
                width="100%"
                direction="right"
              >
                <MidiasBox
                  adicionaFoto={(newImage) => {
                    adicionaFoto(newImage);
                  }}
                  adicionaVideo={(url_video) => {
                    adicionaVideo(url_video);
                  }}
                />
              </SlidingBox>
            </div>

            <FooterPaginas>
              <FooterWrapper theme={theme}>
                <div className="inativar_produto">
                  {produto.id != "0" && (
                    <>
                      {produto.id != "0" && produto.ativo == "1" ? (
                        <div className={"excluir_produto"}>
                          <div onClick={() => handleExcluirProduto()}>
                            Inativar
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>

                <div className="final">
                  <div className="avancado">
                    <Button
                      className="button cinza"
                      title={
                        activeTab == "avancado"
                          ? "Cancelar"
                          : "Configurações avançadas"
                      }
                      width="100%"
                      onClick={(e) => {
                        activeTab == "avancado" ? Voltar() : Avancado();
                      }}
                    />
                  </div>
                  <div className="salvar">
                    <Button
                      type="submit"
                      title={`Salvar e ${
                        produto.ativo == 0 ? "reativar" : "continuar"
                      }`}
                      onClick={(e) => {
                        if (
                          config_integracao.permitir_produtos ||
                          config_integracao.permitir_imagens ||
                          config_integracao.permitir_observacoes
                        ) {
                          e.preventDefault();
                          e.stopPropagation();
                          Avancar();
                        }
                      }}
                      disabled={
                        !config_integracao.permitir_produtos &&
                        !config_integracao.permitir_imagens &&
                        !config_integracao.permitir_observacoes
                      }
                      width="100%"
                      loading={salvando}
                    />
                  </div>
                </div>
              </FooterWrapper>
            </FooterPaginas>
          </>
        )}
      </ViewContainer>
      <Modal
        isOpen={modalCategoria}
        setModal={setModalCategoria}
        children={
          <CategoriaBox
            setCategoriasBox={(value) => {
              setModalCategoria(false);
            }}
            categorias={categorias}
            setNovaCategoria={(value: number) => {
              setNovaCategoria(value);
            }}
          />
        }
        width="100rem"
        maxWidth="99vw"
        align="center"
        backdrop="static"
        justify="center"
        padding="30px"
        animation="opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        margin="0rem 2rem 0rem 2rem"
      />
      <Modal
        isOpen={modalListaDePreco}
        setModal={setModalListaDePreco}
        children={
          <ListaDePrecoBox
            setListaDePrecoBox={(value) => {
              processaVariacoes(produto.variacoes);
              setModalListaDePreco(false);
            }}
            categorias={categorias}
            setNovaListaDePreco={(value: number) => {
              setNovaListaDePreco(value);
            }}
          />
        }
        width="100rem"
        maxWidth="99vw"
        align="center"
        backdrop="static"
        justify="center"
        padding="30px"
        animation="opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        margin="0rem 2rem 0rem 2rem"
      />
      <Modal
        isOpen={modalAtualizando}
        setModal={() => modalAtualizando}
        children={<ModalSucesso message={mensagemModal} type={stateModal} />}
        width="80rem"
        maxWidth="99vw"
        align="center"
        justify="center"
        animation="opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)"
        margin="0rem 2rem 0rem 2rem"
      />
    </>
  );
}

export default Produto;
