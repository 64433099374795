import React, { useContext } from "react";
import { ContainerFreteGratisListagem } from "./styles";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { Toggle } from "../../Toggle/Toggle";
import { copy } from "../../../services/Functions";
import { useTheme } from "../../../contexts/Theme/ThemeContext";

interface FreteGratisListagemProps {
  onEditarFrete: (index: number) => void;
  configIn? : "empresa" | "configuracoes";
  onNovoFrete?: () => void;
}

function formatarCep(cep) {
  return cep.toString().replace(/(\d{5})(\d{3})/, "$1-$2");
}

const FreteGratisListagem: React.FC<FreteGratisListagemProps> = ({
  configIn='empresa',
  onEditarFrete,
  onNovoFrete,
}) => {
  const { fretes_gratis, setFretes_gratis } = useContext(ConfiguracoesContext);
  
  const { theme } = useTheme();
  return (
    <>
      <ContainerFreteGratisListagem theme={theme} configIn={configIn}>
        <div className="containerFreteGratisListagem">
          {configIn === "empresa" && (
            <Titulo
            titulo="Frete Grátis"
            subtitulo={"Gerencie os seus fretes grátis"}
            flexDirection="column-reverse"
            />
          )}
          <div className="fretesContainer">
            {fretes_gratis.map((frete, indexFrete) => {
              return (
                <>
                  <div className="freteContainer" key={indexFrete}>
                    <span className="valor">
                      {(
                        Number.parseInt(frete.valor_minimo) / 100
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}{" "}
                      |
                    </span>
                    <span className="listagem">
                      {frete.brasil_inteiro
                        ? "Brasil inteiro"
                        : frete.por_estado.estados.join(", ")}
                      {frete.por_cep?.ativo && (
                        <>
                        {formatarCep(frete.por_cep.cep_inicio)} - {formatarCep(frete.por_cep.cep_fim)}
                        </>
                      )}
                    </span>
                    <span className="editarContainer">
                      <Toggle
                        value={frete.ativo}
                        onChange={(newValue) => {
                          const copia = copy(fretes_gratis);
                          copia[indexFrete].ativo = newValue;
                          setFretes_gratis(copia);
                        }}
                      ></Toggle>
                      <span
                        onClick={() => {
                          onEditarFrete(indexFrete);
                        }}
                        className={`editar ${!frete.ativo && "disabled"}`}
                      >
                        Editar
                      </span>
                    </span>
                  </div>
                </>
              );
            })}
          </div>
          {configIn === "empresa" && (
            <div
              className="novo"
              onClick={() => {
                onNovoFrete && onNovoFrete();
              }}
            >
              Configure um novo frete grátis
            </div>
          )}
        </div>
      </ContainerFreteGratisListagem>
    </>
  );
};
export default FreteGratisListagem;
