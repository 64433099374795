import styled from "styled-components";
import { ThemeType } from "../../../types/Theme";

export const ContainerCepFreteGratis = styled.div<{theme: ThemeType}>`
  width: 100rem;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
    .cep {
      display: flex;
      gap: 2rem;
    }
`;