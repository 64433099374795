import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import { Button } from "../../components/Button/index.tsx";
import ModaisConfigsPrimeiroAcesso from "../../components/Modal/ModaisConfigsPrimeiroAcesso/ModaisConfigsPrimeiroAcesso";
import Modal from "../../components/Modal/Modal.tsx";
import ModalTermosDeUso from "../../components/Modal/ModalTermosDeUso/ModalTermosDeUso.tsx";
import { AuthContext } from "../../contexts/Auth/AuthContext.tsx";
import { ConfigsPrimeiroAcessoProvider } from "../../contexts/ConfigsPrimeiroAcesso/ConfigsPrimeiroAcessoProvider.tsx";
import { ConfiguracoesProvider } from "../../contexts/Configuracoes/ConfiguracoesProvider";
import { EmpresaProvider } from "../../contexts/Empresa/EmpresaProvider";
import { HeaderProvider } from "../../contexts/Header/HeaderProvider.tsx";
import { PagSeguroProvider } from "../../contexts/PagSeguro/PagSeguroProvider";
import { PedidosProvider } from "../../contexts/Pedidos/PedidosProvider";
import { ScrollDirectionProvider } from "../../contexts/ScrollDirection/ScrollDirectionProvider";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { UsuarioProvider } from "../../contexts/Usuario/UsuarioProvider";
import { configuracoesApi } from "../../hooks/configuracoesApi";
import { EmpresaApi } from "../../hooks/empresaApi.ts";
import { mailerApi } from "../../hooks/mailerApi.ts";
import { PERMISSOES } from "../../services/Consts.tsx";
import { limparConfigsLocalStorage } from "../../services/Functions.tsx";
import { Subtitulo, Titulo } from "../../styled/Styled.ts";
import { Aviso } from "../../types/Empresa.ts";
import { Plano } from "../../types/Plano.ts";
import Header from "../Header";
import MenuLateral from "../MenuLateral/MenuLateral.tsx";
import "./styles.ts";
import { ContainerPrincipal, ModalAvisoContainer } from "./styles.ts";

export const Dashboard = () => {
  const { theme } = useTheme();

  const apiConfiguracoes = configuracoesApi();
  const apiEmpresa = EmpresaApi();
  const mailer = mailerApi();

  const [primeiroAcesso, setPrimeiroAcesso] = useState(false);
  const [modalTermosDeUso, setModalTermosDeUso] = useState(false);
  const [modalAvisos, setModalAvisos] = useState(true);
  const { permissoesUsuario } = useContext(AuthContext);

  const [aviso, setAviso] = useState<Aviso | null>(null);

  const handleAceitarTermosDeUso = async (
    concordoTermos: boolean,
    plano: Plano
  ) => {
    if (!concordoTermos) {
      Swal.fire({
        title: "Você ainda não concordou com os termos de uso!",
        text: "Concorde com os nossos termos para prosseguir.",
      });
      return;
    }
    aceite_termosUso(plano);
  };

  const aceite_termosUso = async (plano: Plano) => {
    const res = await apiEmpresa.aceite_termosUso();
    if (res) {
      enviarEmailTermosUso(res, plano);
    }
    setModalTermosDeUso(false);
  };

  const enviarEmailTermosUso = async (dados, plano) => {
    let detalhes = JSON.parse(dados.detalhes);
    detalhes.data = dados.data;
    detalhes.dadosPlano = plano;
    mailer.enviar(detalhes.email, "templates/termosUso.php", detalhes, true);
  };

  useEffect(() => {
    const init = async () => {
      setModalTermosDeUso(await apiEmpresa.modalTermosDeUso());
      const response = await apiConfiguracoes.verificar_primeiro_accesso();
      if (response === false) {
        //pra garantir, caso acabe sendo aberto as configs em mais de um local
        limparConfigsLocalStorage();
      }
      setPrimeiroAcesso(response); 
    };

    init();
  }, []);

  useEffect(() => {
    const fetchAviso = async () => {
      if(window.location.pathname === '/'){
        const aviso = await apiEmpresa.modalAviso();
        if (aviso) {
          setAviso(aviso)
          setModalAvisos(true)
        }
      }
    }

    fetchAviso();
  }, [window.location.pathname])

  return (
    <>
      <ScrollDirectionProvider>
        <PagSeguroProvider>
          <EmpresaProvider>
            <UsuarioProvider>
              <PedidosProvider>
                <HeaderProvider>
                  <>
                    {(
                      primeiroAcesso === false &&
                      modalTermosDeUso === false &&
                      aviso
                    ) ? (
                      <Modal
                        isOpen={modalAvisos}
                        setModal={setModalAvisos}
                        padding="6rem"
                        width={'85rem'}
                        maxWidth="95vw"

                      >
                        <ModalAvisoContainer theme={theme}>
                            {aviso.titulo && <Titulo theme={theme}>{aviso.titulo}</Titulo>}
                            {aviso.subtitulo && <Subtitulo className="sub-titulo" theme={theme}>{aviso.subtitulo}</Subtitulo>}
                            {aviso.texto && <div className='texto' dangerouslySetInnerHTML={{ __html: aviso.texto }} />}
                            {aviso.botao && (
                              <div className="botao-container">
                                <Button title={aviso.botao} onClick={() => {
                                  if (aviso.acao) window.open(aviso.acao, '_blank');
                                  setModalAvisos(false);
                                }}/>
                              </div>
                            )}   
                        </ModalAvisoContainer >
                      </Modal>
                    ) : (
                      <></>
                    )}
                    {primeiroAcesso === true &&
                      !modalTermosDeUso &&
                      permissoesUsuario.includes(PERMISSOES.CONFIGURACOES) && (
                        <ConfiguracoesProvider>
                          <ConfigsPrimeiroAcessoProvider>
                            <ModaisConfigsPrimeiroAcesso />
                          </ConfigsPrimeiroAcessoProvider>
                        </ConfiguracoesProvider>
                      )}
                    {modalTermosDeUso && (
                      <ModalTermosDeUso
                        onAceitarTermosDeUso={handleAceitarTermosDeUso}
                      />
                    )}
                    <ContainerPrincipal theme={theme}>
                      <MenuLateral />
                      <div className="containerPaginas">
                        <Header />
                        <Outlet />
                      </div>
                    </ContainerPrincipal>
                  </>
                </HeaderProvider>
              </PedidosProvider>
            </UsuarioProvider>
          </EmpresaProvider>
        </PagSeguroProvider>
      </ScrollDirectionProvider>
    </>
  );
};
