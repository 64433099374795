import styled from "styled-components";
import { ThemeType } from "../../types/Theme";

export const ContainerPrincipal = styled.div`
  .containerPaginas {
    padding-left: clamp(120px, 7%, 9999px);
    position: relative;
    right: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
      padding-left: 0;
      width: 100%;
    }
  }
`;

export const ModalAvisoContainer = styled.div<{theme: ThemeType}>`
  .texto {
    position: relative;
    padding: 3rem 0;
    margin: 3rem auto;
    font-size: 2.5rem !important;
    p{
      font-size: 2.5rem !important;
    }
    &::before {
      content: '';
      position: absolute;
      border-radius: 50px;
      width: 50%;
      height: 1px;
      background-color: ${({theme}) => theme.colors.brandSecondary};
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .botao-container {
      display: flex;
      justify-content: center;
  }
`;
