import Swal from "sweetalert2";
import { api } from "./useApi";

export const CestasAbandonadasApi = () => ({
  getCamposPersonalizados: async () => {
    try {
      const response = await api.get(
        "/usuario/configuracoes_campos_personalizados_cestas",
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  setCamposPersonalizados: async (campos: {}) => {
    try {
      const response = await api.post(
        "/usuario/atualizar_campos_personalizados_cestas",
        { campos: campos },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  alterarSituacao: async (
    ids_cestas: number[] | string[],
    situacao_id: number,
  ) => {
    const response = await api
      .post("cestas_abandonadas/altera_situacao_em_lote", {
        ids_cestas: ids_cestas,
        situacao_id: situacao_id,
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Erro ao alterar pedido(s).",
          showConfirmButton: false,
          timer: 1000,
        });
        throw error.response;
      });
    return response.data;
  },

  getCestasImpressao: async (ids_cestas: string[]) => {
    try {
      const response = await api.post(`cesta_abandonada/listar`, { ids_cestas: ids_cestas });
      return response.data.map((cesta: any) => ({ ...cesta }));
    } catch (error) {
      console.error(error);
    }
  },
  

  getCestasAbandonadas: async (
    campos: string[],
    datas: { inicio: string; fim: string },
    filtro: {
      usuario: string;
      catalogo: string;
      clientes_identificados: number;
    },
  ) => {
    try {
      const response = await api.post("cestas_abandonadas/listar", {
        campos: campos,
        datas: datas,
        filtro: filtro,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  getCestaAbandonada: async (id_cesta: string) => {
    try {
      const response = await api.post("cesta_abandonada/listar", {
        ids_cestas: [id_cesta],
      });
      return response.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  getSituacoes: async () => {
    try {
      const response = await api.get("cesta_abandonada/situacoes");
      const data = response.data as { id: number | string; descricao: string }[];
      return data;
    } catch (error) {
      console.error(error);
      return [{ id: 0, descricao: "" }];
    }
  },

  atualizarObservacaoInternaCesta: async (
    id_cesta: string,
    observacao: string,
  ) => {
    try {
      const response = await api.post("cesta_abandonada/inserir_observacao", {
        id_cesta_abandonada: id_cesta,
        observacao: observacao,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});
