import React, { useContext } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import { ContainerCepFreteGratis } from "./styles";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { copy } from "../../../services/Functions";
import { Input } from "../../Input";
import { validateCep } from "../../../services/MasksValidations";
import { unmaskToNumbers } from "../../../services/Unmasks";
import { cepMask } from "../../../services/Masks";

interface CepFreteGratisProps {
  index?: number;
}

const CepFreteGratis: React.FC<CepFreteGratisProps> = ({ index }) => {
  const { fretes_gratis, setFretes_gratis } = useContext(ConfiguracoesContext);
  
  const { theme } = useTheme();

  const handleInputChange = (
    newValue: string,
    name?: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (index !== undefined && fretes_gratis[index]) {
      const numericCep = newValue ? Number(unmaskToNumbers(newValue)) : 0;

      if (!isNaN(numericCep)) {
        const copia = copy(fretes_gratis);

        if (name === "cep_inicio") {
          copia[index].por_cep.cep_inicio = numericCep;
        } else if (name === "cep_fim") {
          copia[index].por_cep.cep_fim = numericCep;
        }

        setFretes_gratis(copia);
      }
    }
  };


  return (
    <ContainerCepFreteGratis theme={theme}>
      {index !== undefined && fretes_gratis[index] && (
        <>
          <Titulo
            titulo="Frete Grátis"
            subtitulo="Insira o CEP para habilitar o frete grátis"
            flexDirection="column-reverse"
          />
          <div className="inputContainer">
            <span className="cep">
            <Input
                allowEmojis={false}
                mask={{
                  mask: cepMask,
                  maskValidation: validateCep,
                  unmask: unmaskToNumbers,
                }}
                type={""}
                value={String(fretes_gratis[index]?.por_cep?.cep_inicio || "")}
                placeholder="00000-000"
                name="cep_inicio"
                error=""
                required={true}
                onChange={(newValue, name, event) =>
                  handleInputChange(newValue, name, event)
                }
                label="CEP Inicio"
              />
              <Input
                allowEmojis={false}
                mask={{
                  mask: cepMask,
                  maskValidation: validateCep,
                  unmask: unmaskToNumbers,
                }}
                type={""}
                value={String(fretes_gratis[index]?.por_cep?.cep_fim || "")}
                placeholder="99999-999"
                name="cep_fim"
                error=""
                required={true}
                onChange={(newValue, name, event) =>
                  handleInputChange(newValue, name, event)
                }
                label="CEP Fim"
              />
            </span>
          </div>
        </>
      )}
    </ContainerCepFreteGratis>
  );
};

export default CepFreteGratis;