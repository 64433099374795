import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as Arrow } from "../../../assets/newImages/icons/arrowLeft.svg";
import BannersCanva from "../../../components/BannersCanva/BannersCanva.tsx";
import BannersShowcase from "../../../components/BannersShowcase/BannersShowcase.tsx";
import { Button } from "../../../components/Button";
import Intermediadores from "../../../components/Configuracoes/Intermediadores/Intermediadores.tsx";
import PagamentoCombinar from "../../../components/Configuracoes/PagamentoCombinar/PagamentoCombinar.tsx";
import Dropdown from "../../../components/Dropdowns/Dropdown.tsx";
import { FooterPaginas } from "../../../components/FooterPaginas/FooterPaginas";
import Form from "../../../components/Form";
import { Input } from "../../../components/Input";
import Titulo from "../../../components/Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import Modal from "../../../components/Modal/Modal";
import { Select } from "../../../components/Select";
import SkeletonCatalogo from "../../../components/Skeletons/SkeletonCatalogo/SkeletonCatalogo";
import Tabs from "../../../components/Tabs/Tabs";
import { Toggle } from "../../../components/Toggle/Toggle";
import { ToggleConfigurar } from "../../../components/Toggle/ToggleConfigurar/ToggleConfigurar";
import { ImageUploadBanners } from "../../../components/Upload/ImageUploadBanners/ImageUploadBanners";
import { CatalogoContext } from "../../../contexts/Catalogo/CatalogoContext";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext.tsx";
import { useTheme } from "../../../contexts/Theme/ThemeContext.tsx";
import { listasDePrecoApi } from "../../../hooks/listasDePreco";
import { boolean, deepCopy, staginEnv } from "../../../services/Functions";
import { currencyMask, percentageMask } from "../../../services/Masks.tsx";
import {
  unmaskPercentage,
  unmaskToNumbers,
} from "../../../services/Unmasks.tsx";
import ListaDePreco from "../../../types/ListaDePreco";
import Produtos from "./Produtos/Produtos";
import "./styles.ts";
import { ContainerCatalogo, ContainerReplicarCatalogo } from "./styles.ts";

interface CatalogosProps {}

const Catalogos: React.FC<CatalogosProps> = ({}) => {
  const { theme } = useTheme();

  const {
    optionsAjustePreco,
    ajustePreco,
    removerCatalogo,
    salvarCatalogo,
    setAjustePreco,
    indice,
    setIndice,
    catalogo,
    setCatalogo,
    loading,
    setLoading,
    outrosUsuarios,
    init: initCatalogos,
    listasDePrecos,
    empresaIntermediadores,
  } = useContext(CatalogoContext);
  const { empresa, setEmpresa } = useContext(EmpresaContext);

  const navigate = useNavigate();

  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const limiteDeBanners = 3;

  const [formValid, setFormValid] = useState(false);

  const [usuariosCatalogoAuxiliar, setUsuariosCatalogoAuxiliar] = useState<any>(
    []
  );

  const [activeTab, setActiveTab] = useState("informacoes");


  const [configsPagamentoCombinar, setConfigsPagamentoCombinar] =
    useState<any>(false);
  const [configsPagamentosEspeciais, setConfigsPagamentosEspeciais] =
    useState<any>(false);

  const [modalReplicarCatalogo, setModalReplicarCatalogo] = useState(false);

  const [tabs, setTabs] = useState([
    { name: "Informações", value: "informacoes" },
    { name: "Banners", value: "banners" },
    // {name: "Avançado", value: "avancado"},
  ]);

  const handleIndiceChange = (
    indiceNovo: string,
    ajuste: string | "aumentar" | "diminuir"
  ) => {
    if (indiceNovo === "" || indiceNovo === "0") {
      setAjustePreco(ajuste ?? "aumentar");
      setCatalogo({
        ...catalogo,
        indice: "1",
      });
      setIndice("");
      return;
    }
    setIndice(indiceNovo);
    setAjustePreco(ajuste);
    const indice = Number.parseFloat(indiceNovo) / 100;
    setCatalogo({
      ...catalogo,
      indice:
        ajuste === "aumentar"
          ? (1 + indice / 100).toString()
          : (1 - indice / 100).toString(),
    });
  };

  const handleSalvarCatalogo = async () => {
    setLoadingSalvar(true);
    try {
      const identificacao = await salvarCatalogo();
      if (identificacao) {
        navigator.clipboard
          .writeText(`${empresa!.dominio_https}${identificacao}${staginEnv()}`)
          .then(
            (res) => {
              Swal.fire({
                title: "Catálogo salvo com sucesso",
                text: "Link copiado para a área de transferência!",
                icon: "success",
                showConfirmButton: false,
                timer: 1250,
              });
            },
            (err) => {
              Swal.fire({
                title: "Catálogo salvo com sucesso",
                icon: "success",
                showConfirmButton: false,
                timer: 1250,
              });
            }
          );
      } else {
        Swal.fire({
          title: "Catálogo salvo com sucesso",
          icon: "success",
          showConfirmButton: false,
          timer: 1250,
        });
      }
      navigate("/painel/catalogos");
    } catch (error) {
      Swal.fire({
        title: "Erro ao salvar catálogo",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    setLoadingSalvar(false);
  };

  const handleAtivarPagamentoEspecial = (newValue: boolean) => {
    let newCatalogo = deepCopy(catalogo);
    if (newValue) {
      newCatalogo.intermediadores = {
        ...empresaIntermediadores,
      };

      if (
        !empresaIntermediadores ||
        !empresaIntermediadores.a_combinar === undefined
      ) {
        //se as configs nao tem intermediador, cria o a combinar pro catalogo
        newCatalogo.intermediadores.a_combinar = {
          ativo: false,
          prazo_contato: "1",
        };
      }
      setConfigsPagamentosEspeciais(true);
    } else {
      // se desativar, remove os intermediadores do catalogo
      newCatalogo.intermediadores = {};
      setConfigsPagamentosEspeciais(false);
    }
    setCatalogo(newCatalogo);
  };

  const onAdicionarProdutos = useCallback(
    (ids_produtos: number[]) => {
      setCatalogo((prevCatalogo) => {
        if (prevCatalogo.id_catalogo_original !== "0" && "") {
          return prevCatalogo;
        }
        const produtos = [...prevCatalogo.produtos];
        ids_produtos.forEach((id) => {
          if (!produtos.some((produto) => produto === id)) {
            produtos.push(id);
          }
        });
        return {
          ...prevCatalogo,
          produtos,
        };
      });
    },
    [setCatalogo]
  );

  const onRemoverProdutos = useCallback(
    (ids_produtos: number[]) => {
      setCatalogo((prevCatalogo) => {
        if (prevCatalogo.id_catalogo_original !== "0" && "") {
          return prevCatalogo;
        }
        const produtos = [...prevCatalogo.produtos];
        ids_produtos.forEach((id) => {
          const index = produtos.findIndex((p) => p === id);
          if (index === -1) return;

          produtos.splice(index, 1);
        });
        return {
          ...prevCatalogo,
          produtos,
        };
      });
    },
    [setCatalogo]
  );

  const handleExcluirCatalogo = () => {
    Swal.fire({
      title: "Tem certeza que deseja excluir este catálogo?",
      text: "Esta ação não poderá ser desfeita!",
      icon: "warning",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Continuar",
      showCancelButton: true,
      showCloseButton: true,
      customClass: {
        confirmButton: 'btn-confirm',
        cancelButton: 'btn-cancel',
        icon: 'custom-swal-icon',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Confirme sua ação",
          text: "Após confirmar a exclusão, a ação não pode ser desfeita.",
          icon: "warning",
          confirmButtonText: "Confirmar (5)",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn-confirm2',
            popup: 'custom-swal-popup',
            icon: 'custom-swal-icon',
            title: 'custom-swal-title',
          },
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            if (confirmButton) {
                confirmButton.disabled = true;
                let secondsLeft = 5;
                const timerInterval = setInterval(() => {
                    secondsLeft--;
                    confirmButton.innerText = `Confirmar (${secondsLeft})`;
                    if (secondsLeft <= 0) {
                      confirmButton.disabled = false;
                      confirmButton.innerText = "Confirmar";
                    }
                }, 1000);
            }
        },
        }).then(async (secondResult) => {
          if (secondResult.isConfirmed) {
            removerCatalogo();
          }
        });
      }
    });
  };

  const memoizedProdutosCatalogo = useMemo(
    () => catalogo.produtos,
    [catalogo.produtos]
  );
  const memoizedAdicionarTodosProdutos = useMemo(
    () => catalogo.configuracoes.adicionarTodosProdutos,
    [catalogo.configuracoes.adicionarTodosProdutos]
  );

  const replicado = !!(catalogo.id_catalogo_original && catalogo.id_catalogo_original !== "0");

  return (
    <>
      <ContainerCatalogo theme={theme}>
        {loading ? (
          <SkeletonCatalogo />
        ) : (
          <>
            <Modal
              isOpen={modalReplicarCatalogo}
              setModal={setModalReplicarCatalogo}
              width={"auto"}
              onUseEffect={() => {
                setUsuariosCatalogoAuxiliar([...catalogo.outros_usuarios]);
              }}
              onBackdropClose={() => {
                setCatalogo({
                  ...catalogo,
                  outros_usuarios: usuariosCatalogoAuxiliar,
                });
              }}
            >
              <ContainerReplicarCatalogo theme={theme}>
                <Titulo
                  titulo={"Replicar esse catálogo <br/> para outros usuários"}
                  subtitulo={
                    "Selecione os usuários que você <br/> deseja replicar esse catálogo"
                  }
                  flexDirection="column-reverse"
                />
                <div className="column-flex">
                  <div className="usuarios-container">
                    {outrosUsuarios
                      .filter(
                        (outroUsuario) =>
                          !usuariosCatalogoAuxiliar.some(
                            (catalogoUsuario) =>
                              catalogoUsuario.id === outroUsuario.id
                          )
                      )
                      .map((usuario) => {
                        return (
                          <>
                            <label className="checkboxBonito flex-label">
                              <input
                                type="checkbox"
                                checked={catalogo.outros_usuarios.some(
                                  (catalogoUsuario) =>
                                    catalogoUsuario.id === usuario.id
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setCatalogo({
                                      ...catalogo,
                                      outros_usuarios: [
                                        ...catalogo.outros_usuarios,
                                        usuario,
                                      ],
                                    });
                                  } else {
                                    setCatalogo({
                                      ...catalogo,
                                      outros_usuarios:
                                        catalogo.outros_usuarios.filter(
                                          (catalogoUsuario) =>
                                            catalogoUsuario.id !== usuario.id
                                        ),
                                    });
                                  }
                                }}
                              />
                              <span>{usuario.email}</span>
                            </label>
                          </>
                        );
                      })}
                  </div>
                  {outrosUsuarios.filter((outroUsuario) =>
                    usuariosCatalogoAuxiliar.some(
                      (catalogoUsuario) =>
                        catalogoUsuario.id === outroUsuario.id
                    )
                  ).length > 0 && (
                    <>
                      <Titulo
                        titulo={"Usuários que já possuem <br/> esse catálogo"}
                        subtitulo={
                          "Caso este catálogo sofra mudança, <br/> ela será replicada para os usuários abaixo."
                        }
                        flexDirection="column-reverse"
                      />
                      <div className="usuarios-container">
                        {outrosUsuarios
                          .filter((outroUsuario) =>
                            usuariosCatalogoAuxiliar.some(
                              (catalogoUsuario) =>
                                catalogoUsuario.id === outroUsuario.id
                            )
                          )
                          .map((usuario) => {
                            return (
                              <>
                                <label className="flex-label">
                                  <span>- {usuario.email}</span>
                                </label>
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                </div>
                <div className="row-flex">
                  <div
                    className="arrow"
                    onClick={() => {
                      setModalReplicarCatalogo(false);
                      setCatalogo({
                        ...catalogo,
                        outros_usuarios: usuariosCatalogoAuxiliar,
                      });
                    }}
                  >
                    <Arrow />
                    Voltar
                  </div>
                  <Button
                    title="Salvar"
                    onClick={() => {
                      setModalReplicarCatalogo(false);
                    }}
                  ></Button>
                </div>
              </ContainerReplicarCatalogo>
            </Modal>
            <Form
              id={"form-cadastro-catalogo"}
              setFormValid={setFormValid}
              onSubmit={() => handleSalvarCatalogo()}
            >
              <div className="grid-container-catalogo">
                <>
                  <div className="left">
                    <Tabs
                      tabs={tabs}
                      value={activeTab}
                      onChange={(newValue) => {
                        setActiveTab(newValue);
                        if (newValue !== "avancado") {
                          setTabs(
                            tabs.filter((tab) => tab.value !== "avancado")
                          );
                        }
                      }}
                    />
                    <div
                      className={`container-conteudo ${
                        activeTab === "informacoes" && "container-ativo"
                      }`}
                    >
                     <div>
                      <div className="bold">Título</div>
                        <Input
                          type={"text"}
                          maxLength={95}
                          showMaxLength={true}
                          value={catalogo.descricao}
                          label="O título aparecerá na parte superior do seu catálogo e ao compartilhar o link dele para seus clientes."
                          sizeLabel="medium"
                          placeholder={"Título do seu catálogo"}
                          required={true}
                          disabled={replicado}
                          onChange={(newValue) =>
                            setCatalogo({
                              ...catalogo,
                              descricao: newValue,
                            })
                          }
                        ></Input>
                      </div>
                      <div className="linha" />
                      <div className="flex">
                        <div className="half-width">
                          <Input
                            type={"text"}
                            showMaxLength={true}
                            value={catalogo.minimo_valor}
                            label="Valor mínimo de compra"
                            sizeLabel="medium"
                            placeholder={"R$ 0,00"}
                            mask={{
                              mask: currencyMask,
                              unmask: unmaskToNumbers,
                            }}
                            disabled={replicado}
                            onChange={(newValue) =>
                              setCatalogo({
                                ...catalogo,
                                minimo_valor: newValue,
                              })
                            }
                          ></Input>
                        </div>
                        <div className="half-width">
                          <Input
                            type={"number"}
                            showMaxLength={true}
                            value={catalogo.minimo_quantidade}
                            label="Quantidade mínima de compra"
                            sizeLabel="medium"
                            placeholder={"0"}
                            disabled={replicado}
                            onChange={(newValue) => {
                              if (
                                Number.parseInt(newValue) > 0 &&
                                Number.parseInt(newValue) < 999999
                              )
                                setCatalogo({
                                  ...catalogo,
                                  minimo_quantidade: newValue,
                                });
                            }}
                          ></Input>
                        </div>
                      </div>
                      <div>
                        <Select
                          value={catalogo.id_tabela}
                          onChange={(newValue) => {
                            setCatalogo({
                              ...catalogo,
                              id_tabela: newValue,
                            });
                          }}
                          label="Tabela de preço"
                          sizeLabel="medium"
                          placeholder="Tabela de preço"
                          disabled={replicado}
                          required={true}
                          options={listasDePrecos}
                        />
                      </div>
                      <div className="flex">
                        <div className="half-width">
                          <Select
                            value={ajustePreco}
                            onChange={(newValue) =>
                              handleIndiceChange(indice.toString(), newValue)
                            }
                            label="Ajuste de preço"
                            sizeLabel="medium"
                            placeholder="Ajuste de preço"
                            disabled={replicado}
                            options={optionsAjustePreco}
                          />
                        </div>
                        <div className="half-width">
                          <Input
                            type="text"
                            value={indice.toString()}
                            label="Porcentagem"
                            sizeLabel="medium"
                            placeholder="0,0%"
                            disabled={replicado}
                            mask={{
                              mask: percentageMask,
                              unmask: unmaskPercentage,
                            }}
                            onChange={(newValue) =>
                              handleIndiceChange(newValue, ajustePreco)
                            }
                          />
                        </div>
                      </div>
                      <div className="exemplo">
                        {indice
                          ? ajustePreco === "aumentar"
                            ? `Exemplo: Se o preço do produto é R$ 10,00, ele passará a ser R$ ${(
                                10 * Number.parseFloat(catalogo.indice)
                              ).toLocaleString("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : `Exemplo: Se o preço do produto é R$ 10,00, ele passará a ser R$ ${(
                                10 * Number.parseFloat(catalogo.indice)
                              ).toLocaleString("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                          : `Insira uma porcentagem para ver um exemplo`}
                      </div>

                      {Number(catalogo.indice) < 1 &&
                        ajustePreco === "diminuir" && (
                          <div className="flex">
                            <label>
                              <Toggle
                                disabled={replicado}
                                value={boolean(
                                  catalogo.configuracoes.mostrarPrecoOriginal
                                )}
                                onChange={(newValue) => {
                                  setCatalogo({
                                    ...catalogo,
                                    configuracoes: {
                                      ...catalogo.configuracoes,
                                      mostrarPrecoOriginal: newValue,
                                    },
                                  });
                                }}
                              />
                              <span>Mostrar preço original das variações</span>
                            </label>
                          </div>
                        )}

                      {boolean(catalogo.principal) === false && (
                        <>
                          <div className="flex">
                            <label>
                              <Toggle
                              disabled={replicado}
                                value={
                                  moment(
                                    catalogo.data_vencimento,
                                    "YYYY/MM/DD HH:mm"
                                  ).isSameOrAfter(
                                    moment("2100-01-01", "YYYY/MM/DD HH:mm")
                                  ) || catalogo.data_vencimento === ""
                                    ? false
                                    : true
                                }
                                onChange={(newValue) => {
                                  if (newValue) {
                                    setCatalogo({
                                      ...catalogo,
                                      data_vencimento: moment()
                                        .add(1, "days")
                                        .format("YYYY/MM/DD HH:mm"),
                                    });
                                  } else {
                                    setCatalogo({
                                      ...catalogo,
                                      data_vencimento: moment(
                                        "2100-01-01 00:00",
                                        "YYYY/MM/DD HH:mm"
                                      ).format("YYYY/MM/DD HH:mm"),
                                    });
                                  }
                                }}
                              />
                              <span>Agendar expiração</span>
                            </label>
                          </div>
                          <div
                            className={`flex expiracao ${
                              moment(
                                catalogo.data_vencimento,
                                "YYYY/MM/DD HH:mm"
                              ).isSameOrAfter(
                                moment("2100-01-01", "YYYY/MM/DD HH:mm")
                              ) || catalogo.data_vencimento === ""
                                ? false
                                : true && "ativo"
                            }`}
                          >
                            <Input
                              label="Ao selecionar data e horário para expiração, seu catálogo, a partir da data selecionada, não ficará mais disponível para seus clientes."
                              sizeLabel="medium"
                              type="datetime-local"
                              disabled={replicado}
                              onChange={(newValue) => {
                                setCatalogo({
                                  ...catalogo,
                                  data_vencimento: moment(
                                    newValue,
                                    "YYYY-MM-DDTHH:mm"
                                  ).format("YYYY/MM/DD HH:mm"),
                                });
                              }}
                              value={moment(
                                catalogo.data_vencimento,
                                "YYYY-MM-DD HH:mm"
                              ).format("YYYY-MM-DDTHH:mm")}
                              placeholder="2023-04-27 11:28"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {/* ------------------- banners --------------------------------- */}
                    <div
                      className={`container-conteudo ${
                        activeTab === "banners" && "container-ativo"
                      }`}
                    >
                      <BannersCanva />
                      <div className="flex">
                        Banner para computadores. Tamanho recomendado 1400 x
                        175px
                      </div>
                      <BannersShowcase type={"imagem_desktop"} />
                      {catalogo.imagem_desktop.length < limiteDeBanners && (
                        <ImageUploadBanners
                          value={""}
                          aspectRatio={8 / 1}
                          minWidth={1200}
                          minHeight={150}
                          resize={{ width: 1400, height: 175 }}
                          onChange={(base64, imagem, size) => {
                            let imagens = [...catalogo.imagem_desktop];
                            imagens.push({
                              base64: base64,
                              imagem: imagem,
                              size: size.toString(),
                            });
                            setCatalogo({
                              ...catalogo,
                              imagem_desktop: imagens,
                            });
                          }}
                        />
                      )}
                      <div className="flex">
                        Banner para celulares. Tamanho recomendado 640 x 320px
                      </div>
                      <BannersShowcase type={"imagem"} />
                      {catalogo.imagem.length < limiteDeBanners && (
                        <ImageUploadBanners
                          value={""}
                          minWidth={640}
                          minHeight={320}
                          aspectRatio={2 / 1}
                          resize={{ width: 640, height: 320 }}
                          onChange={(base64, imagem, size) => {
                            let imagens = [...catalogo.imagem];
                            imagens.push({
                              base64: base64,
                              imagem: imagem,
                              size: size.toString(),
                            });

                            setCatalogo({ ...catalogo, imagem: imagens });
                          }}
                        />
                      )}
                    </div>
                    {/* ------------------- avancado --------------------------------- */}
                    <div
                      className={`container-conteudo ${
                        activeTab === "avancado" && "container-ativo"
                      }`}
                    >
                      <div className="flex space-between no-wrap">
                        <span>Formas de pagamento especiais</span>
                        <ToggleConfigurar
                        disabled={replicado}
                          nome={""}
                          value={
                            catalogo.intermediadores &&
                            (catalogo.intermediadores["pagarme"] !==
                              undefined ||
                              catalogo.intermediadores["pagseguro"] !==
                                undefined ||
                              catalogo.intermediadores["mercadopago"] !==
                                undefined ||
                              catalogo.intermediadores["a_combinar"] !==
                                undefined)
                          }
                          onChange={handleAtivarPagamentoEspecial}
                          onConfigurar={() => {
                            setConfigsPagamentosEspeciais(
                              !configsPagamentosEspeciais
                            );
                          }}
                        />
                      </div>
                      <Dropdown
                        active={configsPagamentosEspeciais}
                        animated={true}
                      >
                        <>
                          {catalogo.intermediadores &&
                            catalogo.intermediadores["pagarme"] !==
                              undefined && (
                              <Intermediadores
                                intermediador="pagarme"
                                configIn="catalogo"
                              />
                            )}
                          {catalogo.intermediadores &&
                            catalogo.intermediadores["pagseguro"] !==
                              undefined && (
                              <Intermediadores
                                intermediador="pagseguro"
                                configIn="catalogo"
                              />
                            )}
                          {catalogo.intermediadores &&
                            catalogo.intermediadores["mercadopago"] !==
                              undefined && (
                              <Intermediadores
                                intermediador="mercadopago"
                                configIn="catalogo"
                              />
                            )}
                          {catalogo.intermediadores &&
                            catalogo.intermediadores["a_combinar"] !==
                              undefined && (
                              <>
                                <div className="flex">
                                  <PagamentoCombinar configIn="catalogo" />
                                </div>
                                {catalogo.intermediadores.a_combinar?.ativo &&
                                  configsPagamentoCombinar && (
                                    <div className="flex">
                                      <Input
                                        type={"number"}
                                        label={"Prazo para contato (em dias)"}
                                        sizeLabel={"medium"}
                                        disabled={replicado}
                                        value={
                                          catalogo.intermediadores.a_combinar
                                            ?.prazo_contato &&
                                          Number.parseInt(
                                            catalogo.intermediadores.a_combinar
                                              ?.prazo_contato
                                          ) > 0
                                            ? catalogo.intermediadores.a_combinar.prazo_contato.toString()
                                            : ""
                                        }
                                        placeholder={"2"}
                                        required={true}
                                        alignLabel="left"
                                        onChange={(newValue) => {
                                          setCatalogo(
                                            deepCopy(catalogo, {
                                              intermediadores: {
                                                a_combinar: {
                                                  prazo_contato: newValue,
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                              </>
                            )}
                        </>
                      </Dropdown>
                      {outrosUsuarios && outrosUsuarios.length > 0 && (
                        <>
                          <div className="linha" />
                          <div className="flex space-between no-wrap">
                            <span>
                              Replicar este catálogo para outros usuários?
                            </span>
                            <ToggleConfigurar
                              nome={""}
                              disabled={replicado}
                              value={
                                catalogo.outros_usuarios &&
                                catalogo.outros_usuarios.length > 0
                              }
                              onChange={(newValue) =>
                                newValue
                                  ? setModalReplicarCatalogo(true)
                                  : setCatalogo(
                                      deepCopy(catalogo, {
                                        outros_usuarios: [],
                                      })
                                    )
                              }
                              onConfigurar={() => {
                                setModalReplicarCatalogo(true);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="middle hideMobile">
                    <div className="linha" />
                  </div>
                  <div className="right">
                    <Tabs
                      tabs={[{ name: "Produtos", value: "produtos" }]}
                      value={"produtos"}
                      tabsNumber={4}
                      onChange={() => {}}
                    />
                    <div className="container-conteudo container-ativo">
                      <div className="flex">
                        Escolha quais produtos deseja adicionar ao seu catálogo
                      </div>
                      <div className="flex">
                        <div className="flex no-wrap">
                          <div className="input">
                            <Input
                              placeholder=""
                              disabled={replicado}
                              name="produtos-catalogo"
                              value={
                                catalogo.configuracoes.adicionarTodosProdutos
                                  ? "true"
                                  : "false"
                              }
                              checked={boolean(
                                catalogo.configuracoes.adicionarTodosProdutos
                              )}
                              id="produto-todos"
                              onChange={(_, __, event) => {
                                setCatalogo({
                                  ...catalogo,
                                  configuracoes: {
                                    ...catalogo.configuracoes,
                                    adicionarTodosProdutos: true,
                                  },
                                });
                              }}
                              type="radio"
                            />
                          </div>
                          <label htmlFor="produto-todos">
                            Todos produtos cadastrados na conta
                          </label>
                        </div>
                        <div className="flex no-wrap">
                          <div className="input">
                            <Input
                              name="produtos-catalogo"
                              disabled={replicado}
                              value={
                                catalogo.configuracoes.adicionarTodosProdutos
                                  ? catalogo.configuracoes.adicionarTodosProdutos.toString()
                                  : "false"
                              }
                              checked={
                                !boolean(
                                  catalogo.configuracoes.adicionarTodosProdutos
                                )
                              }
                              id="produto-selecionados"
                              placeholder=""
                              invalidText="Selecione pelo menos um produto"
                              isValid={{
                                isValidFunction: (value) => {
                                  return !(
                                    !boolean(
                                      catalogo.configuracoes
                                        .adicionarTodosProdutos
                                    ) && catalogo.produtos.length < 1
                                  );
                                },
                                dependencies: [
                                  catalogo.produtos,
                                  catalogo.configuracoes.adicionarTodosProdutos,
                                ],
                              }}
                              onChange={(_, __, event) => {
                                setCatalogo({
                                  ...catalogo,
                                  configuracoes: {
                                    ...catalogo.configuracoes,
                                    adicionarTodosProdutos: false,
                                  },
                                });
                              }}
                              type="radio"
                            />
                          </div>
                          <label htmlFor="produto-selecionados">
                            Selecionar os produtos manualmente
                          </label>
                        </div>
                      </div>
                      <div className="filtrar-produtos-container">
                        <div
                          className={`container-conteudo no-margin ${
                            !boolean(
                              catalogo.configuracoes.adicionarTodosProdutos
                            ) && "container-ativo"
                          }`}
                        >
                          <Tabs
                            tabs={[
                              { name: "Filtrar Produtos", value: "produtos" },
                            ]}
                            className="tab-filtrar"
                            value={"produtos"}
                            tabsNumber={4}
                            onChange={() => {}}
                          />
                          {!boolean(memoizedAdicionarTodosProdutos) && (
                            <Produtos
                              produtosCatalogo={memoizedProdutosCatalogo}
                              onAdicionarProdutos={onAdicionarProdutos}
                              onRemoverProdutos={onRemoverProdutos}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <FooterPaginas>
                    <div className="footer-wrapper">
                      <div
                        className={`green ${
                          catalogo.id === "0" && "hideMobile"
                        }`}
                      >
                        {catalogo.id !== "0" &&
                          boolean(catalogo.principal) === false &&
                          !catalogo.duplicado && (
                            <div onClick={() => handleExcluirCatalogo()}>
                              Excluir
                            </div>
                          )}
                      </div>
                      <div className="right-flex">
                        {catalogo.id !== "0" && !catalogo.duplicado &&(
                        <Link
                        to={`/painel/catalogos/catalogo/${catalogo.id || "0"}/${encodeURIComponent(catalogo.descricao)}/duplicado`}
                        onClick={(e) => {
                          if (replicado) e.preventDefault();
                        }}
                      >
                        <div className={replicado ? "disabled green" : "green"}>
                          Duplicar catálogo
                        </div>
                      </Link>
                                            
                        )}
                        <a href="#avancado">
                          <Button
                            disabled={replicado}
                            className="button cinza"
                            title="Configurações avançadas"
                            onClick={() => {
                              const newTabs = [...tabs];
                              if (newTabs.length === 2) {
                                newTabs.push({
                                  name: "Avançado",
                                  value: "avancado",
                                });
                                setActiveTab("avancado");
                              }
                              setTabs(newTabs);
                            }}
                          />
                        </a>
                        <Button
                          className={`button ${!formValid && "disabled"}`}
                          type="submit"
                          title="Salvar e continuar"
                          disabled={replicado}
                          loading={loadingSalvar}
                        />
                      </div>
                    </div>
                  </FooterPaginas>
                </>
              </div>
            </Form>
          </>
        )}
      </ContainerCatalogo>
    </>
  );
};

export default Catalogos;
