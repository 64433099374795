import { api } from "./useApi";

export const EmpresaApi = () => ({
  get_empresa_usuario: async () => {
    let res = api.post("usuario/get_empresa_usuario").then(
      (res) => {
        if (res.data.dominio != "" || res.data.subdominio != "") {
          res.data.dominio =
            res.data.dominio != "" ? res.data.dominio : res.data.subdominio;
          res.data.dominio_https = "https://" + res.data.dominio + "/";
          if (!res.data.dominio) res.data.dominio = 'app.conectavenda.com.br';
        } else {
          if (window.location.hostname === 'localhost') {
            res.data.dominio = 'localhost/conecta-static/dist/#!/';
            res.data.dominio_https = 'http://localhost/conecta-static/dist/#!/';
          } else {
            res.data.dominio = 'app.conectavenda.com.br';
            res.data.dominio_https = 'https://app.conectavenda.com.br/';
          }
        }
        if (res.data.logo_empresa) {
          res.data.logoEmpresa =
            res.data.logo_empresa.indexOf("https") == -1
              ? ""
              : res.data.logo_empresa;
        } else {
          res.data.logoEmpresa = "";
        }

        return res.data;
      },
      (err) => {
        console.log("err", err);
      },
    );
    return res;
  },

  getIntermediadores: async () => {
    let response = await api.post("/catalogo/intermediadores");
    return response.data;
  },

  verificaVersaoCatalogo: async () => {
    const res = await api.post("empresa/versao_layout_catalogo").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("err", err);
      },
    );
    return res;
  },

  modalTermosDeUso: async () => {
    const res = await api.post("empresa/verificaTermosUso").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("err", err);
      },
    );
    return res === "TERMOS_NAO_ACEITOS" ? true : false;
  },

  modalAviso: async () => {
    const res = await api.post("empresa/verificaAviso").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("err", err);
      },
    );
    return res;
  },

  plano_empresa: async () => {
    const nomePlano = (numero) => {
      if (numero == 1) return "Pós-pago";
      if (numero == 2) return "Pré-pago";
      if (numero == 3) return "Cortesia";
    };

    const nomePeriodicidade = (numero) => {
      if (numero == 1) return "Mensal";
      if (numero == 3) return "Trimestral";
      if (numero == 6) return "Semestral";
      if (numero == 12) return "Anual";
    };

    const nomeFormaPagamento = (numero) => {
      if (numero == 1) return "Boleto bancário";
      if (numero == 2) return "Cartão de crédito";
    };

    let res = await api.post("empresa/plano_empresa").then(
      (res) => {
        let plano = res.data[0];

        plano.tipo_contrato = nomePlano(plano.tipo_contrato);
        plano.periodicidade = nomePeriodicidade(plano.periodicidade);
        plano.forma_pagamento = nomeFormaPagamento(plano.forma_pagamento);

        return plano;
      },
      (err) => {
        console.log("err", err);
      },
    );
    return res;
  },

  aceite_termosUso: async () => {
    const res = api.post("empresa/aceite_termosUso").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("%c err ", "background:#4682B4;color:white;", err);
      },
    );

    return res;
  },
  erp_empresa: async () => {
    const res = api.post("empresa/erp").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("%c err ", "background:#4682B4;color:white;", err);
      },
    );
    return res;
  },
  get_erp_empresa: async () => {
    const res = api.post("empresa/get_erp").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("%c err ", "background:#4682B4;color:white;", err);
      },
    );
    return res;
  },
  get_chave_api: async () => {
    const res = api.post("empresa/get_chave_api").then(
      (res) => {
        return res.data;
      },
      (err) => {
        console.log("%c err ", "background:#4682B4;color:white;", err);
      },
    );
    return res;
  },
  gerar_chave_api: async (
    cadastrarProdutos: boolean,
    cadastrarImagens: boolean,
    selecionarERP: string,
  ) => {
    const res = api
      .post("empresa/gerar_chave_api", { cadastrarProdutos, cadastrarImagens, selecionarERP })
      .then(
        (res) => {
          return res.data;
        },
        (err) => {
          console.log("%c err ", "background:#4682B4;color:white;", err);
        },
      );
    return res;
  },
});
